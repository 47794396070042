@keyframes scroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 100%;
  }
}

body {
  color: #fff;
  font-family: "Arial", sans-serif;
  background-image: 
    linear-gradient(to bottom, rgba(255,255,255,0.5), rgba(255,255,255,0.5)), 
    url(https://blog.chai.ac.cn/assets/e6bc6ce83c38d32e80d2e2a253ff807d.jpg);
  background-size: 100% auto;
  animation: scroll 80s linear infinite;
  margin: 0;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(205, 133, 63);
  background: linear-gradient(
    0deg,
    rgba(205, 133, 63, 1) 0%,
    rgba(205, 133, 63, 1) 50%,
    rgba(205, 133, 63, 0.6) 100%
  );
}

.container {
  width: 100%;
  margin: 15% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}
